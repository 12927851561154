<template>
  <div class="company-list-warpper">
    <div class="filter-box">
      <div class="filter-condition user-content">
        <!-- <div class="keyword-search"> 
          <div class="keyword-input">
            <input
              type="text"
              placeholder="请输入公司名称"
              v-model="companySearchForm.companyName"
            />
          </div>
          <div class="keyword-submit" @click="searchCompany">搜索</div>
        </div> -->
        <div class="filter-row flex">
          <div class="row-title">行业类型：</div>
          <div class="row-content">
            <div :class="ishowhy == true ? 'hybox2' : 'hybox'">
              <span
                :class="{ cur: industryIndex === '' }"
                @click="serachCompanyByFilter('industry', '', '')"
                >不限</span
              >
              <span
                :class="{ cur: industryIndex === index }"
                v-for="(item, index) in industryList"
                :key="`industryList${index}`"
                @click="serachCompanyByFilter('industry', item.id, index)"
                >{{ item.name }}</span
              >
            </div>
            <div class="openhy" @click="changeshow">{{ showhytext }}</div>
          </div>
        </div>
        <div class="filter-row flex">
          <div class="row-title">公司规模：</div>
          <div class="row-content">
            <span
              :class="{ cur: scaleIndex === '' }"
              @click="serachCompanyByFilter('scale', '', '')"
              >不限</span
            >
            <span
              v-for="(item, index) in scaleList"
              :class="{ cur: scaleIndex === index }"
              :key="`scaleList${index}`"
              @click="serachCompanyByFilter('scale', item, index)"
              >{{ item }}</span
            >
          </div>
        </div>
        <div class="filter-row flex">
          <div class="row-title">公司性质：</div>
          <div class="row-content">
            <span
              :class="{ cur: natureIndex === '' }"
              @click="serachCompanyByFilter('nature', '', '')"
              >不限</span
            >
            <span
              :class="{ cur: natureIndex === index }"
              v-for="(item, index) in natureList"
              :key="`natureList${index}`"
              @click="serachCompanyByFilter('nature', item.id, index)"
              >{{ item.name }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="company-list-w user-content">
      <div class="company-list flex" v-loading="isLoading" v-if="!isEmpty">
        <div
          class="company-item"
          v-for="(item, index) in companyList"
          :key="`companyList${index}`"
        >
          <div class="top">
            <div class="logo">
              <img
                :src="
                  item.logo ||
                  require('../../../assets/img/users/company_logo.png')
                "
                alt=""
              />
            </div>
            <div class="company-name wordCut" @click="routeToCompanyNews(item)">
              {{ item.companyName }}
            </div>
            <div class="indus-stage wordCut">
              {{ item.companyIndustryName }}/{{ item.companyNatureName }}
            </div>
            <!-- <div class="advantage wordCut">知识产品与用户服务的数字化工具知识产品与用户服务的数字化工具</div> -->
          </div>
          <div class="bottom flex">
            <div class="bottom-item line">
              <div class="item-green">{{ item.workNumber }}</div>
              <div class="item-gray">在招职位</div>
            </div>
            <div class="bottom-item line">
              <div class="item-green">{{ item.companyScale }}</div>
              <div class="item-gray">公司规模</div>
            </div>
            <div class="bottom-item line">
              <div class="item-green">{{ item.register }}万</div>
              <div class="item-gray">注册资金</div>
            </div>
          </div>
        </div>
      </div>
      <div class="list-no-data flex" v-if="companyList.length === 0">
        <img src="../../../assets/img/users/noJobTip.png" alt="" />
      </div>
    </div>
    <div class="company-more flex user-content" v-show="companyList.length > 0">
      <!-- <div>查看更多</div> -->
      <el-button
        type="primary"
        style="width: 300px"
        :loading="status"
        @click="loadMoreCompany"
        >{{ loadText }}
      </el-button>
    </div>
  </div>
</template>
<script>
import userRequest from "../../../api/user";

export default {
  mounted() {
    let query = this.$route.query;
      if(query.key){
        this.companySearchForm.companyName = query.key
      }
    this.getNatureList();
    this.getIndustryList();
    this.getCompanyList(false);
  },
  data() {
    return {
      showhytext: "展开更多",
      ishowhy: false,
      companyList: [],
      companySearchForm: {
        pageNum: 1,
        pageSize: 12,
        natureId: "",
        industryId: "",
        companyScale: "",
        companyName: null,
      },
      natureList: [],
      natureIndex: "",
      industryList: [],
      industryIndex: "",
      scaleList: [
        "10人以下",
        "10-50人",
        "50-200人",
        "200-500人",
        "500-1000人",
        "1000人以上",
      ],
      scaleIndex: "",
      total: 0,
      status: false,
      isEmpty: false, // 是否没有数据
      isLoading: false,
    };
  },
  methods: {
    changeshow() {
      this.ishowhy = !this.ishowhy;
      if (this.ishowhy) {
        this.showhytext = "收起";
      } else {
        this.showhytext = "展开更多";
      }
      
      // query.key && (this.companySearchForm.companyName = query.key);
    },
    searchCompany() {
      this.getCompanyList(false);
    },
    getCompanyList(isPush) {
      this.status = true;
      this.isLoading = true;
      this.isEmpty = false;
      userRequest.getCompanyList(this.companySearchForm).then((res) => {
        if (res.code === 200) {
          this.status = false;
          this.total = res.data.total;
          if (
            this.companySearchForm.pageNum === 1 &&
            res.data.list.length === 0
          ) {
            this.isEmpty = true;
          } else {
            this.isEmpty = false;
          }

          if (!isPush) {
            this.companyList = res.data.list;
          } else {
            this.companyList = this.companyList.concat(res.data.list);
          }
        }
        this.isLoading = false;
      });
    },
    routeToCompanyNews(item) {
      this.$router.push({
        path: "/user/companyNews",
        query: {
          companyId: item.id,
        },
      });
    },
    serachCompanyByFilter(flag, id, index) {
      this.companySearchForm.pageNum = 1;
      if (flag === "nature") {
        this.natureIndex = index;
        this.companySearchForm.natureId = id;
      } else if (flag === "industry") {
        this.industryIndex = index;
        this.companySearchForm.industryId = id;
      } else if (flag === "scale") {
        this.scaleIndex = index;
        this.companySearchForm.companyScale = id;
      }
      this.getCompanyList(false);
    },
    getNatureList() {
      userRequest.getNatureList().then((res) => {
        if (res.code === 200) {
          this.natureList = res.data;
        }
      });
    },
    getIndustryList() {
      userRequest.getIndustryList().then((res) => {
        if (res.code === 200) {
          this.industryList = res.data;
        }
      });
    },
    loadMoreCompany() {
      if (
        this.companySearchForm.pageNum * this.companySearchForm.pageSize >=
        this.total
      ) {
        return;
      }
      this.companySearchForm.pageNum++;
      this.getCompanyList(true);
    },
  },
  computed: {
    loadText() {
      if (this.status) {
        return "正在加载";
      } else {
        if (
          this.companySearchForm.pageNum * this.companySearchForm.pageSize >=
          this.total
        ) {
          return "没有更多";
        } else {
          return "加载更多";
        }
      }
    },
  },
  watch: {
    $route(){
      this.companySearchForm.companyName= this.$route.query.key
    },
    'companySearchForm.companyName':{
      deep:true,
        handler: function(newV, oldV) {
          this.getCompanyList(false);
        }

      }
  }
};
</script>
<style lang="scss" scoped>
@import "../../../assets/css/global.scss";
.hybox {
  height: 100px;
  overflow: hidden;
}
.hybox2 {
  height: auto;
  overflow: hidden;
}
.openhy {
  height: 20px;
  background: #f9f9f9;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  cursor: pointer;
  color: #999;
}
.keyword-search {
  height: 46px;
  margin-bottom: 20px;
  .keyword-input {
    float: left;
    box-sizing: border-box;
    padding: 0 15px;
    width: 791px;
    height: 46px;
    line-height: 20px;
    border-color: #fff;
    border: 1px solid #e8e8e8;
    border-right: 0;
    input {
      display: block;
      width: 100%;
      height: 40px;
      border: none;
      outline: none;
      font-size: 14px;
      &::placeholder {
        color: #bbb;
        font-size: 14px;
      }
    }
  }
  .keyword-submit {
    cursor: pointer;
    float: left;
    padding: 16px 55px;
    background-color: $main;
    color: #fff;
    border: 0;
    &:hover {
      background: $hover;
    }
  }
}
.wordCut {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
}

.list-no-data {
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
}

.company-list-warpper {
  /*margin-top: 80px;*/
  height: 100%;

  .filter-box {
    background: #fff;
    padding: 30px 0;

    .filter-condition {
      user-select: none;

      .filter-row {
        margin-bottom: 13px;

        .row-title {
          width: 70px;
          font-size: 14px;
          line-height: 25px;
          font-weight: 500;
          color: #414a60;
        }

        .row-content {
          flex: 1;
          font-size: 13px;
          color: #414a60;

          span {
            cursor: pointer;
            display: inline-block;
            line-height: 25px;
            margin-right: 6px;
            padding: 0 8px;

            &.cur {
              color:#ffffff;
              background:$main;
            }

            &:hover {
               color:$main;
              // background:$main;
            }
          }
        }
      }
    }
  }

  .company-list {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    // min-height: 800px;
    .company-item {
      width: 260px;
      height: 270px;
      background: #fff;
      margin-top: 16px;
      margin-right: 16px;
      padding: 0px 10px;
      border: 1px solid #eee;
      &:nth-child(4n + 4) {
        /*margin-right: 0;*/
      }

      &:hover {
        box-shadow: 0 0 10px 0 rgba(56, 81, 76, 0.12);
      }

      .top {
        margin: 0 13px;
        padding: 20px 0 14px;
        text-align: center;
        border-bottom: 1px dashed #e0e0e0;
        position: relative;

        .logo {
          text-align: center;
          overflow: hidden;
          img {
            max-width: 235px;
            height: 80px;
          }
        }

        .company-name {
          cursor: pointer;
          margin-top: 13px;
          font-size: 16px;
          color: #333;

          &:hover {
            color: $main;
          }
        }

        .indus-stage {
          padding-top: 5px;
          margin: 3px auto 0;
          color: #999;
        }

        .advantage {
          padding-top: 3px;
          margin: 5px auto 0;
          color: #555;
        }
      }

      .bottom {
        justify-content: center;
        margin-top: 17px;
        padding: 0 14px;
        font-size: 14px;

        .bottom-item {
          width: 90px;
          // height: 50px;
          &.line {
            position: relative;

            .item-green {
              text-align: center;
              color: $green;
              font-weight: 600;
            }

            .item-gray {
              text-align: center;
              margin-top: 4px;
              font-size: 12px;
              color: #999;
            }

            &::after {
              position: absolute;
              top: 0;
              right: 0;
              content: "";
              height: 30px;
              width: 1px;
              background: #ededed;
            }
          }
        }
      }
    }
  }

  .company-more {
    justify-content: center;
    // margin: 30px 0;
    margin-top: 30px;
    padding-bottom: 30px;

    div {
      cursor: pointer;
      width: 218px;
      height: 34px;
      text-align: center;
      line-height: 34px;
      background: $main;
      color: #fff;

      &:hover {
        background: $hover;
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  * {
    box-sizing: border-box;
  }
  .filter-row {
    display: none;
  }
  .filter-form {
    display: none;
  }

  .keyword-input {
    width: 80vw !important;
  }
  .keyword-submit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20vw !important;
    height: 46px !important;
    padding: 0px !important;
  }

  .user-content {
    width: 100vw;
    margin: 0 auto;
  }
  .company-list {
    justify-content: center;
  }
}
</style>